<template>
  <div>
    <gql-table
      ref="phasesGqlTable"
      :fields="columns"
      :query="query"
      query-type="accounts"
      sort-by="close_datetime"
      :sort-desc="true"
      :actions="actions"
      :toggle-filters="true"
      @move="onMove"
      @document="openDocument"
      @hide="onHide"
      @edit="onEdit"
      @contract="onResendContract"
    >
      <template #cell(show_details)="row">
        <show-details :row="row" />
      </template>

      <template #row-details="row">
        <row-detail :row="row" />
      </template>
      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(account_type_name)="data">
        {{ accountTypes[data.item.account_type_name] }}
      </template>

      <template #cell(account_subtype_name)="data">
        {{ accountSubtypes[data.item.account_type_name][data.item.account_subtype_name] }}
      </template>

      <template #cell(rules)="data">
        {{ data.item.rule_max_daily_drawdown }}%|{{ data.item.rule_max_total_drawdown }}%|{{ data.item.rule_min_trading_days }}|{{ data.item.rule_first_withdrawal_after }}|{{ data.item.rule_withdrawal_after }} - {{ ddTypes.find(rule => rule.value === data.item.rule_drawdown_type).label }}
      </template>

      <template #cell(tags)="data">
        <tags-list
          :tags="data.item.tags"
          :extras-tags="getAccountExtraTags(data.item)"
        />
      </template>

      <template #cell(account_id)="data">
        <b-link :to="{ name: 'account-detail', params: { id: data.item.account_id } }">
          {{ data.item.account_id }}
        </b-link>
        <risk-score-badge
          v-if="data.item.risk_score"
          class="mx-1"
          :score-results="data.item.risk_score_results"
          :score="data.item.risk_score"
        />

        <b-link
          :to="{ name: 'tools-risk-score', params: { id: data.item.account_id } }"
          target="_blank"
        >
          <b-button
            v-b-tooltip.hover.top="'Open risk score analysis'"
            class="m-50"
            variant="outline-primary"
            size="sm"
          >
            RS
          </b-button>
        </b-link>
      </template>

      <template #cell(parent_id)="data">
        <b-link
          v-if="data.item.parent"
          :to="{ name: 'account-detail', params: { id: data.item.parent.account_id } }"
        >
          {{ data.item.parent.account_id }}
        </b-link>
      </template>

      <template #cell(order_id)="data">
        <b-link
          v-if="data.item.order"
          :to="{ name: 'order-detail', params: { id: data.item.order.order_number } }"
        >
          {{ data.item.order.order_number }}
        </b-link>
      </template>

      <template #cell(balance)="data">
        <b-badge
          :variant="data.item.balance>data.item.deposit ? 'success' : 'danger'"
        >
          {{ formatCurrency(data.item.balance, data.item.currency.label) }}
        </b-badge>
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.deposit, data.item.currency.label) }}
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(start_datetime)="data">
        {{ formatDateTime(data.item.start_datetime) }}
      </template>

      <template #cell(end_datetime)="data">
        {{ formatDateTime(data.item.end_datetime) }}
      </template>

      <template #cell(close_datetime)="data">
        {{ formatDateTime(data.item.close_datetime) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="`light-${stateVariants[data.item.state]}`">
          {{ data.item.state }}
        </b-badge>
        <b-badge
          v-if="data.item.under_review"
          :variant="'light-warning'"
        >
          under review
        </b-badge>
      </template>
    </gql-table>
    <b-modal
      id="accountModal"
      title="Edit Account"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <account-form
        module-name="phasesList"
        :account="accountEdit"
        @submit="onEditSubmit()"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BBadge, BButton, BCol, BLink, BModal, BRow, VBTooltip,
} from 'bootstrap-vue'
import { dispatch } from 'vuex-pathify'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import ShowDetails from '@/components/ui/tables/ShowDetails.vue'
import RowDetail from '@/components/page/accounts/components/RowDetail.vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import {
  ACCOUNT_PHASES,
  ACCOUNTS_SHORT,
  DEFAULT_FILTER_ACCOUNTS_TYPES,
  DEFAULT_PLATFORMS_SWITCHER,
  DRAWDOWN_TYPES_ENUM,
  PHASES,
  STATE_VARIANTS,
} from '@/enums/accounts'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'
import TagsList from '@/components/page/TagsList.vue'
import { formatCurrency, formatDateTime } from '@/plugins/formaters'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import { getUserExtraTags } from '@/plugins/userUtils'
import AccountForm from '@/components/forms/account/AccountForm.vue'
import AccountAPI from '@/api/account'
import store from '@/store'
import PandadocApi from '@/api/pandadoc'

const query = [
  'id',
  'account_id',
  'account_type_name',
  'account_subtype_name',
  'balance',
  'deposit',
  'createdAt',
  'start_datetime',
  'end_datetime',
  'close_datetime',
  'state',
  'password',
  'investor_password',
  'risk_score',
  'risk_score_results',
  'phase_move_check',
  'equity_check',
  'rule_drawdown_type',
  'rule_first_withdrawal_after',
  'rule_inheritance',
  'rule_max_daily_drawdown',
  'rule_max_days',
  'rule_max_total_drawdown',
  'rule_min_profit',
  'rule_min_trading_days',
  'rule_special',
  'rule_withdrawal_after',
  'extras_tags',
  'under_review',
  'comment',
  'doc_id',
  { order: { fields: ['id', 'order_number'] } },
  { parent: { fields: ['id', 'account_id'] } },
  { rootParent: { fields: ['id', 'account_id', { order: { fields: ['id', 'order_number'] } }] } },
  { currency: { fields: ['label'] } },
  { user: { fields: ['firstName', 'lastName', 'id', 'email', 'risk_score_results', 'risk_score', { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } }] } },
  { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
]

const actions = [
  {
    text: 'Move to next phase',
    icon: 'ChevronsUpIcon',
    emit: 'move',
  },
  {
    text: 'Account details',
    icon: 'SearchIcon',
    emit: 'detail',
  },
  {
    text: 'Show contract',
    icon: 'FileIcon',
    emit: 'document',
  },
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Resend contract',
    icon: 'SendIcon',
    emit: 'contract',
  },
  {
    text: 'Hide account',
    icon: 'EyeOffIcon',
    emit: 'hide',
  },
]

export default {
  components: {
    BModal,
    AccountForm,
    BRow,
    BButton,
    BCol,
    BBadge,
    TagsList,
    RiskScoreBadge,
    BLink,
    GqlTable,
    RowDetail,
    ShowDetails,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      query,
      actions,
      accountTypes: ACCOUNTS_SHORT,
      accountSubtypes: ACCOUNT_PHASES,
      ddTypes: DRAWDOWN_TYPES_ENUM,
      stateVariants: STATE_VARIANTS,
      accountEdit: null,
      columns: [
        'show_details',
        {
          name: 'user',
          label: 'User',
        },
        {
          name: 'account_type_name',
          label: 'Account type',
          filterable: { type: 'multienum', enum: Object.keys(ACCOUNTS_SHORT) },
          default: DEFAULT_FILTER_ACCOUNTS_TYPES,
        },
        {
          name: 'account_subtype_name',
          label: 'Account phase',
          filterable: { type: 'multienum', enum: Object.keys(PHASES) },
          default: ['phase1', 'phase2', 'phase3'],
        },
        {
          name: 'account_subtype_name',
          label: 'Phase',
        },
        {
          name: 'rules',
          sortable: true,
          label: 'ID|DD|TD|FW|W',
        },
        {
          name: 'tags',
          label: 'Tags',
          filterable: {
            type: 'multientity', queryType: 'tags', value: 'id', label: 'name', search: 'name',
          },
        },
        {
          name: 'userTags',
          label: 'User tag',
          filterable: {
            type: 'multientity', queryType: 'tags', value: 'id', label: 'name', search: 'name',
          },
          visible: false,
        },
        {
          name: 'account_id',
          label: 'Account number',
          filterable: 'like',
        },
        {
          name: 'parent_id',
          label: 'Parent number',
        },
        {
          name: 'order_id',
          label: 'Order number',
        },
        {
          name: 'deposit',
          label: 'Deposit',
        },
        {
          name: 'balance',
          label: 'Balance',
        },
        {
          name: 'createdAt',
          label: 'Created at',
          sortable: true,
        },
        {
          name: 'start_datetime',
          label: 'Start date',
          sortable: true,
        },
        {
          name: 'end_datetime',
          label: 'End date',
          sortable: true,
        },
        {
          name: 'close_datetime',
          label: 'Close date',
          sortable: true,
        },
        {
          name: 'state', label: 'State', filterable: { type: 'multienum', enum: ['failed', 'completed', 'active'] }, default: ['completed'],
        },
        {
          name: 'rule_drawdown_type', label: 'DD type', filterable: { type: 'multienum', enum: DRAWDOWN_TYPES_ENUM }, visible: false,
        },
        {
          name: 'children_account',
          label: 'Children account',
          filterable: { type: 'is', visible: false },
          default: false,
          visible: false,
        },
        {
          name: 'accountPlatform',
          label: 'Platform',
          filterable: {
            type: 'multienum',
            enum: DEFAULT_PLATFORMS_SWITCHER,
          },
          visible: false,
        },
        {
          name: 'actions',
        },
      ],
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getAccountExtraTags,
    getUserExtraTags,
    onEditSubmit() {
      this.$bvModal.hide('accountModal')
    },
    onEdit(item) {
      dispatch('accountsList/get', item.account_id).then(data => {
        this.accountEdit = data.account_data
        this.$bvModal.show('accountModal')
      })
    },
    onMove(item) {
      if (item.state === 'active') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Unable to level up active account',
            variant: 'danger',
          },
        })
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: 'User will be notified about this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, level up!',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.postNextAccountPhase(item.id).then(() => {
            this.$refs.phasesGqlTable.reloadAll(true)
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to proceed',
                icon: 'WarningIcon',
                text: 'Account is probably moved already',
                variant: 'warning',
              },
            })
            this.$refs.phasesGqlTable.reloadAll(true)
          })
        }
      })
    },
    openDocument(item) {
      window.open(`https://app.pandadoc.com/a/#/documents/${item.doc_id}`, '_blank')
    },
    onHide(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} own by ${item.user.email} will be hide in phases section`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, hide!',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('phasesList/update', { id: item.id, data: { hideAt: new Date().toISOString() } })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hide account',
                  icon: 'EyeOffIcon',
                  text: 'Account was hide successfully.',
                  variant: 'success',
                },
              })
              this.$refs.phasesGqlTable.reloadAll(true)
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hide account',
                  icon: 'XCircleIcon',
                  text: 'Hiding account failed.',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    onResendContract(item) {
      if (item.account_subtype_name !== 'funded' || item.confirmedAt) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Create contract is possible for funded and unconfirmed account only!',
            variant: 'danger',
          },
        })
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: `Contract for account ${item.account_id} will be created.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, create',
      }).then(result => {
        if (result.isConfirmed) {
          PandadocApi.postSendContract(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Contract created',
                  icon: 'CheckCircleIcon',
                  text: 'Contract created',
                  variant: 'success',
                },
              })
            })
        }
      })
    },
  },
}
</script>
